import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import WeaponsStatsTable from "@/game-fortnite/components/WeaponsStatsTable";
import {
  WEAPON_CATEGORIES,
  WEAPON_CATEGORIES_SYMBOLS,
} from "@/game-fortnite/constants/weaponCategories.mjs";
import useWeaponsFromItems from "@/game-fortnite/utils/use-weapons-from-items.mjs";
import Container from "@/shared/ContentContainer.jsx";

const categories = Object.getOwnPropertySymbols(WEAPON_CATEGORIES)
  .filter((s) => s !== WEAPON_CATEGORIES_SYMBOLS.fortniteAllWeapons)
  .map((s) => WEAPON_CATEGORIES[s].key);

const ActiveWeapons = () => {
  const { t } = useTranslation();
  const unfilteredWeapons = useWeaponsFromItems();
  const weapons = useMemo(() => {
    return Object.values(unfilteredWeapons).filter(
      (item) => item.isActive && Object.keys(item.stats || {}).length !== 0,
    );
  }, [unfilteredWeapons]);
  const unvaultedCategories = Array.from(
    new Set(weapons.map((w) => w?.category).filter((w) => w)),
  );

  return (
    <Container className="flex column gap-sp-8">
      {(unvaultedCategories.length > 0 ? unvaultedCategories : categories).map(
        (category) => {
          const symbol = Object.getOwnPropertySymbols(WEAPON_CATEGORIES).find(
            (s) => WEAPON_CATEGORIES[s].key === category,
          );
          const weaponCategory = WEAPON_CATEGORIES[symbol];
          const ids = weapons
            .filter((w) => w?.category === category)
            .map((w) => w.id);
          return (
            <div key={category} className="flex column justify-center gap-8">
              <p className="type-title--bold">
                {t(
                  ...(weaponCategory?.t ||
                    (["fortnite:category", "Category"] as Translation)),
                )}
              </p>
              <WeaponsStatsTable weaponIds={ids} />
            </div>
          );
        },
      )}
    </Container>
  );
};

export default ActiveWeapons;
